import {Routes} from '@angular/router';
import {AuthGuard} from './pages/autenticacao/autenticacao.guard';
import {LoginTokenComponent} from './pages/login-token/login-token.component';

export const routes: Routes = [
    {
        path: "token/:token",
        component: LoginTokenComponent
    },
    {
        path: 'tabs',
        loadChildren: () => import('./pages/tabs/tabs.routes').then((m) => m.routes),
        canActivate: [AuthGuard],
    },
    {
        path: 'entrar',
        loadComponent: () => import('./pages/autenticacao/login/login.page').then(m => m.LoginPage)
    },
    {
        path: 'recuperar-senha',
        loadComponent: () => import('./pages/autenticacao/recuperar-senha/recuperar-senha.page').then(m => m.RecuperarSenhaPage)
    },
    {
        path: 'ajuda',
        loadComponent: () => import('./pages/ajuda-login/ajuda-login.page').then(m => m.AjudaLoginPage)
    },
    {
        path: '',
        redirectTo: 'entrar',
        pathMatch: 'full'
    },
    {
        path: 'avaliacao-item',
        loadComponent: () => import('./pages/boletins/avaliacao-item/avaliacao-item.page').then(m => m.AvaliacaoItemPage)
    },


    // {
    //   path: '',
    //   redirectTo: 'tabs',
    //   loadChildren: () => import('./pages/tabs/tabs.routes').then((m) => m.routes),
    // },
];
